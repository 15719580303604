<template>
  <div class="reserve">
    <div class="right">
      <div>
        <breadCrumb :siderContent="siderName" />
      </div>
      <div class="top">
        <a-input class="ipt" placeholder=" 请输入会员昵称/手机号/订单号" v-model="form.keywords" />
        <div class="name">余额变动场景:</div>
        <a-select class="mySelect" placeholder="请选择余额变动场景" @change="getKey"  v-model="form.chang_type">
          <a-select-option   
            v-for="(item) in items"
            :key="item.key+'o'" 
            :value="item.key"
            >{{item.name}}
          </a-select-option>  
        </a-select>
        <div class="name">变动日期:</div>
        <a-range-picker 
        class="myRange" 
        :placeholder="['开始时间', '结束时间']"
         @change="handleChange"
         v-model="time"
        >
          <a-icon slot="suffixIcon" type="calendar" />
        </a-range-picker>
        <a-button class="smallOkBtn" @click="search" >查询</a-button>
      </div>
      <div>
        <a-table
          :columns="columns"
          :data-source="detail"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          bordered
          :pagination="{
            current:searchForm.page,
            total:total,
            pageSize:7,
          }"
          @change="handleTableChange"
          :locale="{emptyText: '暂无数据'}"
        >
          <span slot="base" slot-scope="text,record">
            <div>{{record.nick_name}}</div>
            <div>{{record.mobile}}</div>
          </span>
          <span slot="chang_type" slot-scope="text">
            <span style="background:#f5f5f5;border:1px solid #cccccc;padding:0.5vh">{{text}}</span>
          </span>
          <span slot="number" slot-scope="text">
            <div >￥{{text}}</div>
          </span>

        </a-table>
      </div>
    </div>
  </div>
</template>
<script>
import breadCrumb from "@/components/BreadCrumb/BreadCrumb";
import {transactionDetails} from '@/request/bill.js';
const columns = [
  {
    title: "用户/会员信息",
    dataIndex: "base",
    scopedSlots: { customRender: "base" },
  },
  {
    title: "余额变动场景",
    dataIndex: "chang_type",
    ellipsis: true,
    scopedSlots: { customRender: "chang_type" },
  },
  {
    title: "变动金额/积分",
    dataIndex: "number",
    scopedSlots: { customRender: "number" },
    ellipsis: true,  
  },
  {
    title: "描述说明",
    dataIndex: "description",
    // scopedSlots: { customRender: "way" },
  },
  {
    title: "变动时间",
    dataIndex: "create_time",
    ellipsis: true,
  },
];
export default {
  data() {
    return {
      members: [],
      siderName: "交易明细", //sider切换内容
      form:{
        keywords:"",
        start_time:"",
        end_time:"",
        chang_type:"",
        page:1,
        listRows:7,
      },
      detail:[],//细节数组
      items: [
        { key:"", name: "全部" },
        { key: 1, name: "订单支付" },
        { key: 2, name: "订单退款" },
        { key: 3, name: "商品退款" },
        { key: 4, name: "挂账" },
        { key: 5, name: "挂账核销" },
        { key: 6, name: "会员充值" },
        { key: 7, name: "新增积分" },
        { key: 8, name: "消费积分" },
      ],
      columns,
      searchForm:{
         page:1,
      },
      current:1,//当前页
      total:0,//总数据条数
      time:[],//时间数组
    };
  },
  components: {
    breadCrumb,
  },
  mounted(){
    this.datailRequest()
  },
  methods:{
    getKey(value){//获取状态
      this.status=value;
    },
    // filter(str){
    //   switch(str){
    //     case 1:
    //       return "订单支付"
    //       break;
    //     case 2:
    //       return "订单退款"
    //       break; 
    //     case 3:
    //       return "商品退款"
    //       break;
    //     case 4:
    //       return "挂账"
    //       break; 
    //     case 5:
    //       return "挂账核销"
    //       break;
    //     case 6:
    //       return "会员充值"
    //       break;  
    //     case 7:
    //       return "新增积分"
    //       break;
    //     case 8:
    //       return "消费积分"
    //       break;            
    //   }
    // },
    datailRequest(){
      this.searchForm=this.form;
      transactionDetails(this.searchForm)
        .then((res) => {
          console.log(JSON.stringify(res.data.data.detail.data))
          if(res.data.status===200){
              this.detail=res.data.data.detail.data;
              // console.log(JSON.stringify(this.detail))
              this.total=res.data.data.detail.total && res.data.data.detail.total;
            }               
          })
        .catch(result => {
            return false;
      })
    },
    handleChange(value,dateString) { //获取时间
     if(dateString){
      //  this.time=dateString;
       this.form.start_time=dateString[0] && dateString[0];
       this.form.end_time=dateString[1] && dateString[1];
     }
    },
    search(){
      this.searchForm.page=1;
     this.datailRequest()
    },
    handleTableChange(e){ //点击分页
      this.form.page = e.current;
      this.datailRequest()
  },
  }
};
</script>

<style lang="less" scoped>
.reserve {
  width: 100%;
  height: 95.4vh;
  overflow-y: auto;
  overflow-x:hidden;
  background: #f8f8ff;
  .right {
    width: 83vw;
  }
}
/deep/.ant-table{
  margin: 0 2vh 0 2vh;
}
.top {
  display: flex;
  margin: 1.5vh 0 1.5vh 1.5vh;
  .ipt {
    width: 22vw;
    margin-left: 1vh;
    margin-right: 2vh;
    height:3.9vh;
  }
  .myRange {
    width: 22vw;
    margin-right: 2.5vh;
  }
  .mySelect {
    width: 10vw;
    margin-right: 2vh;
  }
  .name{
    height:3.9vh;
    line-height: 3.9vh;
    font-size: 1.8vh;
    margin-right: 5px;
  }
  .myButton {
    height:3.9vh;
    width: 8vw;
    margin-right: 2.5vh;
    background-color: #ff6700;
    border: none;
    color: white;
    font-size: 1.8vh;
  }
  .button {
    width: 10vh;
    height:3.9vh;
    font-size: 1.8vh;
  }
  .myTable{
    margin: 2.5vh;
  }
}
/deep/.ant-pagination{
  margin-right:4vw;
}
/deep/td{
  padding:1.5vh !important;
  background: white !important;
  font-size: 1.8vh;
}  
/deep/th{
  background: rgba(248, 248, 255, 1) !important;
  font-size: 1.8vh;
  padding:10px !important;
}
/deep/.ant-calendar-picker-input{
   font-size: 1.8vh;
   height: 3.9vh;
}
/deep/.ant-select-selection__rendered{
 font-size: 1.8vh;
  height: 3.9vh;
  line-height: 3.9vh;
}
/deep/.ant-select-selection--single{
 height: 3.9vh;
  line-height: 3.9vh;
}
/deep/.card-title{
  margin-top:2vh !important;
}
</style>